"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
//@prepros-prepend jquery.1.12.4.js
//@prepros-prepend modernizr.min.js
//@prepros-prepend plugins/salvattore/salvattore.min.js
//@prepros-prepend plugins/validate/jquery.validate.js
//@prepros-prepend plugins/addtocalendar/atc.min.js
//@prepros-prepend plugins/clipboardjs/clipboard.min.js
//@prepros-prepend plugins/highlight/jquery.highlight-5.js

/**
 * Owl Carousel v2 Accessibility Plugin
 * Version 0.2.1
 * © Geoffrey Roberts 2016
 */
;

(function ($, window, document) {
  var Owl2A11y = function Owl2A11y(carousel) {
    this._core = carousel;
    this._initialized = false;
    this._core._options = $.extend(Owl2A11y.defaults, this._core.options);
    this.$element = this._core.$element;
    var setCurrent = $.proxy(function (e) {
      this.setCurrent(e);
    }, this);
    this._handlers = {
      'initialized.owl.carousel': $.proxy(function (e) {
        this.setupRoot();

        if (e.namespace && !this._initialized) {
          this.setupFocus();
          this.setupKeyboard();
        }

        this.setCurrent(e);
      }, this),
      'changed.owl.carousel': setCurrent,
      'translated.owl.carousel': setCurrent,
      'refreshed.owl.carousel': setCurrent,
      'resized.owl.carousel': setCurrent
    };
    this.$element.on(this._handlers);
  };
  /* PREFERENCES */

  /**
   * Contains default parameters, if there were any.
   */


  Owl2A11y.defaults = {};
  /* EVENT HANDLERS */

  /**
   * Adds support for things that don't map nicely to the root object
   * such as event handlers.
   */

  Owl2A11y.eventHandlers = {};
  /**
   * Get a callback for keyup events within this carousel.
   *
   * @return callback
   *   An event callback that takes an Event as an argument.
   */

  Owl2A11y.prototype.getDocumentKeyUp = function () {
    var self = this;
    return function (e) {
      var eventTarg = $(e.target),
          targ = self.focused(eventTarg),
          action = null;

      if (!!targ) {
        if (e.keyCode == 37 || e.keyCode == 38) {
          action = 'prev.owl.carousel';
        } else if (e.keyCode == 39 || e.keyCode == 40) {
          action = 'next.owl.carousel';
        } else if (e.keyCode == 13) {
          if (eventTarg.hasClass('owl-prev')) action = 'prev.owl.carousel';else if (eventTarg.hasClass('owl-next')) action = 'next.owl.carousel';else if (eventTarg.hasClass('owl-dot')) action = 'click';
        }

        if (!!action) targ.trigger(action);
      }
    };
  };
  /* SETUP AND TEAR DOWN */

  /**
   * Assign attributes to the root element.
   */


  Owl2A11y.prototype.setupRoot = function () {
    this.$element.attr({
      role: 'listbox',
      tabindex: '0'
    });
  };
  /**
   * Setup keyboard events for this carousel.
   */


  Owl2A11y.prototype.setupKeyboard = function () {
    // Only needed to initialise once for the entire document
    if (!this.$element.attr('data-owl-access-keyup')) {
      this.$element.bind('keyup', this.getDocumentKeyUp()).attr('data-owl-access-keyup', '1');
    }

    this.$element.attr('data-owl-carousel-focusable', '1');
  };
  /**
   * Setup focusing behaviour for the carousel.
   */


  Owl2A11y.prototype.setupFocus = function () {
    // Only needed to initialise once for the entire document
    this.$element.bind('focusin', function () {
      $(this).attr({
        'data-owl-carousel-focused': '1',
        'aria-live': 'polite'
      }).trigger('stop.owl.autoplay');
    }).bind('focusout', function () {
      $(this).attr({
        'data-owl-carousel-focused': '0',
        'aria-live': 'off'
      }).trigger('stop.owl.autoplay');
    }); // Add tabindex to allow navigation to be focused.

    if (!!this._core._plugins.navigation) {
      var navPlugin = this._core._plugins.navigation,
          toFocus = [];

      if (!!navPlugin._controls.$previous) {
        toFocus.push(navPlugin._controls.$previous);
      }

      if (!!navPlugin._controls.$next) {
        toFocus.push(navPlugin._controls.$next);
      }

      if (!!navPlugin._controls.$indicators) {
        toFocus.push(navPlugin._controls.$indicators.children());
      }

      $.each(toFocus, function () {
        this.attr('tabindex', '0');
      });
    }
  };
  /**
   * Assign attributes to the root element.
   */


  Owl2A11y.prototype.destroy = function () {
    this.$element.unbind('keyup', this.eventHandlers.documentKeyUp).removeAttr('data-owl-access-keyup data-owl-carousel-focusable').unbind('focusin focusout');
  };
  /* HELPER FUNCTIONS */

  /**
   * Identifies all focusable elements within a given element.
   *
   * @param DOMElement elem
   *   A DOM element.
   *
   * @return jQuery
   *   A jQuery object that may refer to zero or more focusable elements.
   */


  Owl2A11y.prototype.focusableElems = function (elem) {
    return $(elem).find('a, input, select, button, *[tabindex]');
  };
  /**
   * Identifies all focusable elements within a given element.
   *
   * @param jQeury elems
   *   A jQuery object that may refer to zero or more focusable elements.
   * @param boolean enable
   *   Whether focus is to be enabled on these elements or not.
   */


  Owl2A11y.prototype.adjustFocus = function (elems, enable) {
    elems.each(function () {
      var item = $(this);
      var newTabIndex = '0',
          storeTabIndex = '0';
      currentTabIndex = item.attr('tabindex'), storedTabIndex = item.attr('data-owl-temp-tabindex');

      if (enable) {
        newTabIndex = typeof storedTabIndex != 'undefined' && storedTabIndex != '-1' ? item.attr('data-owl-temp-tabindex') : '0';
        storedTabIndex = newTabIndex;
      } else {
        newTabIndex = '-1';
        storedTabIndex = typeof currentTabIndex != 'undefined' || currentTabIndex != '-1' ? currentTabIndex : '0';
      }

      item.attr({
        tabindex: newTabIndex,
        'data-owl-temp-tabindex': storeTabIndex
      });
    });
  };
  /**
   * Get the root element if we are focused within it.
   *
   * @param DOMElement targ
   *   An element that might be within this carousel.
   *
   * @return mixed
   *   Either the jQuery element containing the root element, or NULL.
   */


  Owl2A11y.prototype.focused = function (targ) {
    var targ = $(targ);

    if (targ.attr('data-owl-carousel-focused') == 1) {
      return targ;
    }

    var closest = targ.closest('[data-owl-carousel-focused="1"]');
    if (closest.length > 0) return closest;
    return null;
  };
  /* UPDATE FUNCTIONS */

  /**
   * Identify active elements, set WAI-ARIA sttributes accordingly,
   * scroll to show element if we need to, and set up focusing.
   *
   * @param Event e
   *   The triggering event.
   */


  Owl2A11y.prototype.setCurrent = function (e) {
    var targ = this.focused($(':focus')),
        element = this._core.$element,
        stage = this._core.$stage,
        focusableElems = this.focusableElems,
        adjustFocus = this.adjustFocus;

    if (!!stage) {
      this._core.$stage.children().each(function (i) {
        var item = $(this);
        var focusable = focusableElems(this); // Use the active class to determine if we can see it or not.
        // Pretty lazy, but the Owl API doesn't make it easy to tell
        // from indices alone.

        if (item.hasClass('active')) {
          item.attr('aria-hidden', 'false');
          adjustFocus(focusable, true);
        } else {
          item.attr('aria-hidden', 'true');
          adjustFocus(focusable, false);
        }
      });

      if (!!targ) {
        // Focus on the root element after we're done moving,
        // but only if we're not using the controls.
        setTimeout(function () {
          var newFocus = element;

          if ($(':focus').closest('.owl-controls').length) {
            newFocus = $(':focus');
          }

          newFocus.focus();
        }, 250);
      }
    }
  };

  $.fn.owlCarousel.Constructor.Plugins['Owl2A11y'] = Owl2A11y;
})(window.Zepto || window.jQuery, window, document);

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // Home page
    home: {
      init: function init() {
        $(document).ready(function () {
          // $("#frontpage_slider_sv").owlCarousel({
          //   items: 1,
          //   responsive: false,
          // });
          $('#owl-slider').owlCarousel({
            loop: false,
            rewind: true,
            margin: 36,
            nav: true,
            dots: false,
            autoplay: false,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 3
              }
            },
            onInitialized: function onInitialized(event) {
              $('#owl-slider').find('.owl-stage-outer').append('<p class="alert alert-success show-on-focus">Använd piltangenterna för att navigera.</p>');
              $('#frontpage_puffar .owl-item').each(function (i, el) {
                $(this).on('click', function (e) {
                  $(this).find('.info>a').trigger('customClick');
                });
              });
              $('#frontpage_puffar .owl-item .info>a').on('customClick', function (e) {
                window.location.href = $(this).attr('href');
              });
            }
          });
        });
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    search: {
      init: function init() {
        $(document).ready(function () {
          // Kolla om det finns förslag på sökningar
          if ($(".suggestion_list.sv li").length > 0) {
            $(".suggestion_list.sv").prepend("<h3>Förslag på sökningar</h3>");
          }

          if ($(".suggestion_list.en li").length > 0) {
            $(".suggestion_list.en").prepend("<h3>Proposals for other searches</h3>");
          } // Hämta resultat för sidor och poster


          var pagePostCount = $("#pagePostCountResults").val();
          $("#getPagePostResults").text(pagePostCount); // Hämta resultat för dokument

          var documentCount = $("#documentCountResults").val();
          $("#getDocumentResults").text(documentCount); // Hämta resultat för namnstämpelregistret

          var namnstampCount = $("#namnstampCountResults").val();
          $("#getNamnstampResults").text(namnstampCount); // Hämta resultat för Ackrediterade organ

          var ackrediteradeOrganCount = $("#ackrediteringCountResults").val();
          $("#getAckrediteradeOrganResults").text(ackrediteradeOrganCount);
          var certificateCount = $("#certificateCountResult").val();
          $("#getCertificateResults").text(certificateCount);
        });
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // Bedömarutbildning
    bedomarutbildning: {
      init: function init() {
        $(document).ready(function () {
          //*********************************************************************
          // Bedömarutbildning
          //*********************************************************************
          $(".reg_bedomarutbildning").click(function () {
            $("#reg_bedomarutbildning").slideToggle();
          });

          (function ($, W, D) {
            var JQUERY4U = {};
            JQUERY4U.UTIL = {
              setupFormValidation: function setupFormValidation() {
                //Validation rules // Kursbokning
                $("#bedomarutbildning_form").validate({
                  rules: {
                    diarienummer: {
                      required: true
                    },
                    firstname: {
                      required: true
                    },
                    lastname: {
                      required: true
                    },
                    company: {
                      required: true
                    },
                    postadress: {
                      required: true
                    },
                    postnummer_ort: {
                      required: true
                    },
                    faktura_adress: {
                      required: true
                    },
                    faktura_postnummer_ort: {
                      required: true
                    },
                    phone: {
                      required: true
                    },
                    orgnr: {
                      required: true
                    },
                    email: {
                      required: true,
                      email: true
                    },
                    ovriga_onskemal: {
                      required: true
                    },
                    send_material_to_email: {
                      required: true
                    }
                  },
                  messages: {
                    firstname: "",
                    lastname: "",
                    postadress: "",
                    postnummer_ort: "",
                    phone: "",
                    email: "",
                    orgnr: "",
                    diarienummer: "",
                    company: "",
                    faktura_adress: "",
                    faktura_postnummer_ort: "",
                    ovriga_onskemal: "",
                    send_material_to_email: ""
                  },
                  submitHandler: function submitHandler(form) {
                    checkSubmit(form);
                  }
                });
              }
            }; //when the dom has loaded setup form validation rules

            $(D).ready(function ($) {
              JQUERY4U.UTIL.setupFormValidation();
            });
          })(jQuery, window, document); //Sätt klasser på input beroende på händelse vid validering
          //****************************************************


          $(".row input.req, .row select.req, .row textarea.req").blur(function () {
            var $this = $(this);
            var parent = $this.parent();

            if ($(this).val().trim().length != 0) {
              $this.addClass("valid");
              $this.removeClass("error");
            } else {
              $this.addClass("error");
              $this.removeClass("valid");
            }
          });
        });
      }
    },
    // Ämnesområden
    // 'amnesomraden': {
    page_template_page_template_3: {
      init: function init() {
        $(document).ready(function () {
          //*********************************************************************
          // Ämnesområden
          //*********************************************************************
          // Sortera option i selecten och visa laddikon
          //****************************************************
          var options = $("#all_amnesomraden_select option").not(":eq(0)");
          var arr = options.map(function (_, o) {
            return {
              t: $(o).text(),
              v: o.value
            };
          }).get();
          arr.sort(function (o1, o2) {
            return o1.t > o2.t ? 1 : o1.t < o2.t ? -1 : 0;
          });
          options.each(function (i, o) {
            o.value = arr[i].v;
            $(o).text(arr[i].t);
          }); // Visa flera ämnesområden.
          //****************************************************

          size_li = $(".omraden li").size();
          x = 5;
          data_more = 1;

          while (data_more < $('.omraden').length + 1) {
            $(".omraden_" + data_more).each(function () {
              var count_li = $(".omraden_" + data_more + " li").length;

              if (count_li < x) {
                $(".show_more_" + data_more).addClass("hide");
                $(".show_less_" + data_more).addClass("hide");
              } else {
                $(".show_more_" + data_more).addClass("show");
              }

              $(".omraden_" + data_more + " li:lt(" + x + ")").show(100);
            });
            data_more++;
          }

          $(".show_more").click(function () {
            var toggle = $(this).attr("data-toggle");
            x = x + 7 <= size_li ? x + 7 : size_li;
            $(".omraden_" + toggle + " li:lt(" + x + ")").show();
            $(this).removeClass("show");
            $(".show_less_" + toggle).show();
          });
          $(".show_less").click(function () {
            var toggle = $(this).attr("data-toggle-less");
            $(".omraden_" + toggle + " li").not(":lt(5)").hide();
            $(this).hide();
            $(".show_more_" + toggle).addClass("show");
          });
        }); // Slut på Ämnesområden ($(document).ready(function())
      }
    },
    // Kör script på alla sidor som använder sig av sidmallen certifieringsorgan
    certifieringsorgan: {
      init: function init() {
        // Hämta ut ID på rubriken som ska scrollas till
        var pageUrl = window.location.href; // @link https://stackoverflow.com/questions/48447629/new-urllocation-href-doesnt-work-in-ie#answer-48496498

        function getQueryString() {
          var key = false,
              res = {},
              itm = null; // get the query string without the ?

          var queryString = location.search.substring(1); // check for the key as an argument

          if (arguments.length > 0 && arguments[0].length > 1) {
            key = arguments[0];
          } // make a regex pattern to grab key/value


          var pattern = /([^&=]+)=([^&]*)/g; // loop the items in the query string, either
          // find a match to the argument, or build an object
          // with key/value pairs

          while (itm = pattern.exec(queryString)) {
            if (key !== false && decodeURIComponent(itm[1]) === key) {
              return decodeURIComponent(itm[2]);
            } else if (key === false) {
              res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
            }
          }

          return key === false ? res : null;
        }

        if (getQueryString("view") == null) {
          return;
        }

        var element = document.getElementById(getQueryString("view"));
        var headerHeight = document.getElementById("header").offsetHeight; // Scrolla till rubriken

        element.scrollIntoView(true); // Hämta hur mycket det scrollats ner på y-axeln

        var scrolledY = window.pageYOffset; // Scrolla ner till sektionen

        if (scrolledY && headerHeight) {
          if (window.innerWidth > 736) {
            window.scroll(0, scrolledY - (25 + headerHeight));
          } else {
            window.scroll(0, scrolledY);
          }
        }
      },
      finalize: function finalize() {}
    },
    // Alla sidor
    common: {
      init: function init() {
        // JavaScript to be fired on all pages
        //*********************************************************************
        // ALLMÄNA FUNKTIONER
        //*********************************************************************
        $(document).ready(function () {
          var kbActive = "keyboard-active";
          var docBody = document.body.classList; // Om man klickat på TAB, lägg till klass på body

          document.addEventListener("keydown", function (event) {
            if (event.keyCode === 9 && docBody.contains(kbActive) === false) {
              docBody.add(kbActive);
            }
          }); // Om man klickat någonstans med musen så tas klassen bort igen

          document.addEventListener("mousedown", function (event) {
            if (docBody.contains(kbActive) === true) {
              docBody.remove(kbActive);
            }
          });
          /**
           * Öppnar och stänger boxarna för certifieringsorgan
           */

          $(document).click(function (event) {
            $(".tgr-certifieringsorg-box").removeClass("active");
            $(".required-specs-lists").slideUp(200);
          });
          $(".required-specs-lists").click(function () {
            event.stopPropagation();
          });
          $(".tgr-certifieringsorg-box").click(function (event) {
            $(this).toggleClass("active");
            $($(this).next(".required-specs-lists")).slideToggle(200);
            event.stopPropagation();
          }); // Visning / Dölj av grund/ändringsförfattningar

          $(".sort_docs").click(function () {
            $(".sort_docs").removeClass("current");
            $(this).addClass("current");
            var alt = $(this).attr("data-docs");

            if (alt == "all") {
              $(".info ul li").slideDown(400);
            } else if (alt == "grundforfattning") {
              $(".info ul li.grundforfattning").slideDown(400);
              $(".info ul li.andringsforfattning").slideUp(400);
            } else if (alt == "andringsforfattning") {
              $(".info ul li.andringsforfattning").slideDown(400);
              $(".info ul li.grundforfattning").slideUp(400);
            }
          });
          $(".li_1990").first().addClass("first");
          $(".li_1991").first().addClass("first");
          $(".li_1992").first().addClass("first");
          $(".li_1993").first().addClass("first");
          $(".li_1994").first().addClass("first");
          $(".li_1995").first().addClass("first");
          $(".li_1995").first().addClass("first");
          $(".li_1996").first().addClass("first");
          $(".li_1997").first().addClass("first");
          $(".li_1998").first().addClass("first");
          $(".li_1999").first().addClass("first");
          $(".li_2000").first().addClass("first");
          $(".li_2001").first().addClass("first");
          $(".li_2002").first().addClass("first");
          $(".li_2003").first().addClass("first");
          $(".li_2004").first().addClass("first");
          $(".li_2005").first().addClass("first");
          $(".li_2006").first().addClass("first");
          $(".li_2007").first().addClass("first");
          $(".li_2008").first().addClass("first");
          $(".li_2009").first().addClass("first");
          $(".li_2010").first().addClass("first");
          $(".li_2011").first().addClass("first");
          $(".li_2012").first().addClass("first");
          $(".li_2013").first().addClass("first");
          $(".li_2014").first().addClass("first");
          $(".li_2015").first().addClass("first");
          $(".li_2016").first().addClass("first");
          $(".li_2017").first().addClass("first");
          $(".li_2018").first().addClass("first");
          $(".li_2019").first().addClass("first");
          $(".li_2020").first().addClass("first"); // Flytta kontaktinformation på engelska
          //*********************************************************************

          $(".kontaktuppgifter_kontor").insertBefore("#anchor_phone-fax-and-email"); // Hantering av meddelande
          //*********************************************************************

          $(".header_message_unsub").delay(5000).slideUp("fast"); // Visa fler Nyheter
          //*********************************************************************

          nyheter_size_li = $("#page-news-content ul li").size();
          n = 11;
          $("#page-news-content ul li:lt(" + n + ")").show();
          $("#loadMoreNews").click(function () {
            if (nyheter_size_li == n) {
              $("#loadMoreNews").addClass("disabled");
            } else {
              n = n + 10 <= nyheter_size_li ? n + 10 : nyheter_size_li;
              $("#page-news-content ul li:lt(" + n + ")").show();
            }
          });
          $(".relaterad_information").insertBefore("#relaterad_information");
          $(".document_crm").insertBefore("#document_crm"); // Toggla MID-certifikat
          //****************************************************

          $(".r_toggle_cert").click(function () {
            $(".cert_div").slideUp();
            var toggle = $(this).attr("data-toggle");

            if ($("[data-more=" + toggle + "]").is(":hidden")) {
              $("[data-more=" + toggle + "]").addClass("active");
              $("[data-paragraph=" + toggle + "]").addClass("active");
              $("[data-paragraph=" + toggle + "] .r_toggle_cert").addClass("snurr");
              $("[data-more=" + toggle + "]").slideDown(300);
            } else {
              $("[data-more=" + toggle + "]").slideUp(300);
              $("[data-more=" + toggle + "]").removeClass("active");
              $("[data-paragraph=" + toggle + "]").removeClass("active");
              $("[data-paragraph=" + toggle + "] .r_toggle_cert").removeClass("snurr");
            }
          }); //Flytta MID-certifikat
          //****************************************************

          $("#certifikat_rep").insertBefore("#anchor_matinstrument-som-omfattas-av-swedacs-regelverk"); // Ta bort data-src när bilden är laddad.
          //****************************************************

          [].forEach.call(document.querySelectorAll("img[data-src]"), function (img) {
            img.setAttribute("src", img.getAttribute("data-src"));

            img.onload = function () {
              img.removeAttribute("data-src");
            };
          }); // Spåra Utklick (GA)
          //****************************************************

          function _gaLt(event) {
            var el = event.srcElement || event.target;

            while (el && (typeof el.tagName == "undefined" || el.tagName.toLowerCase() != "a" || !el.href)) {
              el = el.parentNode;
            }

            if (el && el.href) {
              var link = el.href;

              if (link.indexOf(location.host) == -1 && !link.match(/^javascript\:/i)) {
                var hitBack = function hitBack(link, target) {
                  target ? window.open(link, target) : window.location.href = link;
                };

                var target = el.target && !el.target.match(/^_(self|parent|top)$/i) ? el.target : false;
                ga("send", "event", "Outgoing Links", link, document.location.pathname + document.location.search, {
                  hitCallback: hitBack(link, target)
                });
                event.preventDefault ? event.preventDefault() : event.returnValue = !1;
              }
            }
          }

          var w = window;
          w.addEventListener ? w.addEventListener("load", function () {
            document.body.addEventListener("click", _gaLt, !1);
          }, !1) : w.attachEvent && w.attachEvent("onload", function () {
            document.body.attachEvent("onclick", _gaLt);
          }); //Parallax på Swedac Magasin
          //****************************************************
          // $(window).scroll(function(e){
          // 	parallax();
          // });
          // function parallax(){
          // 	var scrolled = $(window).scrollTop();
          // 	$('#page-header-parent .post_thumbnail .image').css('background-position-y',-(scrolled*0.5)+'px');
          // }
          // Flytta divar för att få rutnät på Swedac Magasin
          //****************************************************

          $("#page-magasin-content .article_5").after($("#page-magasin-content .article_10")); //****************************************************
          // SINGLE SIDOR
          //****************************************************
          // Kolla om p-tag har bild.
          //****************************************************

          $(".single-post .post-content p:has(img)").filter(function () {
            return !$.trim($(this).text()).length;
          }).addClass("has_class"); // Ämnesområden Visa/Dölj interna länkar
          //****************************************************

          $(".r_more, .paragraph_start").click(function () {
            var toggle = $(this).attr("data-toggle");
            $(".r_more").removeClass("snurr");
            $(this).attr("aria-expanded", "true");

            if ($("[data-more=" + toggle + "]").is(":hidden")) {
              $("[data-more=" + toggle + "]").addClass("active");
              $("[data-paragraph=" + toggle + "]").addClass("active");
              $("[data-paragraph=" + toggle + "] .r_more").addClass("snurr");
              $("[data-more=" + toggle + "]").slideToggle(300);
            } else {
              $("[data-more=" + toggle + "]").slideUp(300);
              $("[data-more=" + toggle + "]").removeClass("active");
              $("[data-paragraph=" + toggle + "]").removeClass("active");
              $("[data-paragraph=" + toggle + "] .r_more").removeClass("snurr");
              $(this).attr("aria-expanded", "false");
            }
          }); // Ämnesområden / Ankarlänkar - Scrolla till ID
          //****************************************************

          $(document).on("click", 'a[href^="#"]', function (e) {
            var id = $(this).attr("href");
            var $id = $(id);

            if ($id.length === 0) {
              return;
            } else {
              e.preventDefault();
              var pos = $(id).offset().top - 160;
              $("body, html").animate({
                scrollTop: pos
              });
              $($id).addClass("scrolled_to");
              setTimeout(function () {
                $(".scrolled_to .paragraph_start").addClass("active");
                $(".scrolled_to .hidden_content").addClass("active");
                $(".scrolled_to .hidden_content").slideDown();
              }, 200);
            }
          });
        }); //Slut på Allmäna funktioner ($(document).ready(function ())

        $(document).ready(function () {
          // Click event handler for toggling menu
          $('.menu-toggle button').on('click', function () {
            // Check if the first input has the class 'hidden'
            var isHiddenFirst = $('.menu-toggle button:first').hasClass('hidden'); // Check if the second input has the class 'hidden'

            var isHiddenSecond = $('.menu-toggle button:last').hasClass('hidden');
            $('.menu-toggle button').toggleClass('hidden');
            $('#header').toggleClass('open');
            $('.mobile-menu').toggleClass('open');
            $('html').toggleClass('lock-height'); // Update aria-expanded attribute based on the presence of the 'hidden' class in each button

            $('.menu-toggle button:first').attr('aria-expanded', isHiddenFirst);
            $('.menu-toggle button:last').attr('aria-expanded', isHiddenSecond);
          }); //*********************************************************************
          // HELA SÖKNINGEN
          //*********************************************************************
          // Karta på sökningen för Ackrediterade organ
          //****************************************************

          $(window).bind("load", function () {// if ($('#searchresult-map').is(':empty')){
            //   	$("#searchresult-map").hide();
            //   	$("#searchresult-mapinfotext").hide();
            // }
          }); // Neka symboler i söken
          //****************************************************

          $("#search_form .search-field").bind("keyup blur", function () {
            var node = $(this);
            node.val(node.val().replace("&", ""));
          }); // Visa avancerad sökning
          //****************************************************

          $(".toggle-adv").click(function () {
            $(this).toggleClass("active");
            $("#submenu").toggleClass("open");
          }); // Visa sökformuläret
          //****************************************************

          $(".search-icon, .search-icon-mobile").click(function () {
            $(".toggle-search").slideToggle();
          }); // Villkor för sökningen
          //****************************************************

          $all_search = $(".alla_sokkallor");
          $all_alt = $(".search_alt");
          $swedac_se = $("#swedac_se");
          $foreskrifter_dokument = $("#foreskrifter_dokument");
          $certifikat_godkannanden = $("#certifikat_godkannanden");
          $swedac_se_empty = $(".intern_sok_empty");
          $swedac_se_hidden = $(".intern_sok");
          $swedac_se_dokument = $(".intern_sok_dokument");
          $only_docs = $(".intern_sok_dokument_only");
          $only_certs = $(".intern_sok_certifikat_only");
          $(function () {
            if (window.location.href.indexOf("swedac_se=on") > -1) {
              $($swedac_se).prop("checked", true);

              if ($($swedac_se).is(":checked")) {
                $($swedac_se_empty).prop("disabled", true);
                $($swedac_se_hidden).prop("disabled", false);
              }

              $($all_search).prop("indeterminate", true);
            } //Dokument


            if (window.location.href.indexOf("foreskrifter_dokument=on") > -1) {
              $($foreskrifter_dokument).prop("checked", true);

              if ($($foreskrifter_dokument).is(":checked")) {
                $($swedac_se_empty).prop("disabled", true);
                $($swedac_se_empty).prop("disabled", true);
                $($swedac_se_dokument).prop("disabled", true);
                $($only_docs).prop("disabled", false);
              }

              $($all_search).prop("indeterminate", true);
            } //


            if (window.location.href.indexOf("certifikat_godkannanden=on") > -1) {
              $($certifikat_godkannanden).prop("checked", true);

              if ($($certifikat_godkannanden).is(":checked")) {
                $($swedac_se_empty).prop("disabled", true);
                $($swedac_se_empty).prop("disabled", true);
                $($only_certs).prop("disabled", false);
              }

              $($all_search).prop("indeterminate", true);
            }

            if (window.location.href.indexOf("foreskrifter_dokument=on") > -1 && window.location.href.indexOf("swedac_se=on") > -1) {
              $($foreskrifter_dokument).prop("checked", true);
              $($swedac_se).prop("checked", true);

              if ($($foreskrifter_dokument).is(":checked") && $($swedac_se).is(":checked")) {
                $($swedac_se_empty).prop("disabled", true);
                $($swedac_se_dokument).prop("disabled", false);
                $($only_docs).prop("disabled", true);
                $($swedac_se_hidden).prop("disabled", false);
              }

              $($all_search).prop("indeterminate", true);
            }

            if (window.location.href.indexOf("ackrediterande_organ=on") > -1) {
              $("#ackrediterande_organ").prop("checked", true);
              $($all_search).prop("indeterminate", true);
            }

            if (window.location.href.indexOf("namnstampelregistret=on") > -1) {
              $("#namnstampelregistret").prop("checked", true);
              $($all_search).prop("indeterminate", true);
            }

            if (window.location.href.indexOf("foreskrifter_dokument=on") > -1 && window.location.href.indexOf("swedac_se=on") > -1 && window.location.href.indexOf("ackrediterande_organ=on") > -1 && window.location.href.indexOf("namnstampelregistret=on") > -1) {
              $("#namnstampelregistret").prop("checked", true);
              $("#ackrediterande_organ").prop("checked", true);
              $($swedac_se_empty).prop("disabled", true);
              $($($only_docs)).prop("disabled", true);
              $($swedac_se_hidden).prop("disabled", false);
              $($swedac_se_dokument).prop("disabled", false);
              $($all_search).prop("checked", true);
            }
          });
          $(function () {
            $($all_search).change(function () {
              if ($(this).is(":checked")) {
                $($swedac_se_empty).prop("disabled", true);
                $($($only_docs)).prop("disabled", true);
                $($swedac_se_hidden).prop("disabled", false);
                $($swedac_se_dokument).prop("disabled", false);
                $($all_alt).not($all_search).each(function (i, k) {
                  $(k).prop("checked", true);
                });
              } else {
                $($swedac_se_empty).prop("disabled", false);
                $($($only_docs)).prop("disabled", true);
                $($swedac_se_hidden).prop("disabled", true);
                $($swedac_se_dokument).prop("disabled", true);
                $($all_alt).each(function (i, k) {
                  $(k).prop("checked", false);
                });
              }
            });
            $($all_alt).not($all_search).click(function () {
              $($all_search).prop("indeterminate", true);
            });
          });
          $(function () {
            $($swedac_se).change(function () {
              if ($(this).is(":checked")) {
                if ($($foreskrifter_dokument).is(":checked")) {
                  $($swedac_se_dokument).prop("disabled", false);
                  $($only_docs).prop("disabled", true);
                }

                $($swedac_se_empty).prop("disabled", true);
                $($swedac_se_hidden).prop("disabled", false);
              } else {
                if ($($foreskrifter_dokument).is(":checked")) {
                  $($swedac_se_dokument).prop("disabled", true);
                  $($only_docs).prop("disabled", false);
                }

                $($swedac_se_empty).prop("disabled", false);
                $($swedac_se_hidden).prop("disabled", true);
              }
            });
          });
          $(function () {
            $($foreskrifter_dokument).change(function () {
              if ($(this).is(":checked")) {
                if ($($swedac_se).is(":checked")) {
                  $($swedac_se_dokument).prop("disabled", false);
                  $($only_docs).prop("disabled", true);
                } else {
                  $($swedac_se_empty).prop("disabled", true);
                  $($swedac_se_dokument).prop("disabled", true);
                  $($only_docs).prop("disabled", false);
                }
              } else {
                if ($($swedac_se).is(":checked")) {
                  $($swedac_se_dokument).prop("disabled", true);
                  $($only_docs).prop("disabled", true);
                } else {
                  $($swedac_se_empty).prop("disabled", false);
                  $($swedac_se_dokument).prop("disabled", true);
                  $($only_docs).prop("disabled", true);
                }
              }
            });
          }); // Markera sökordet
          //*********************************************************************

          var term_result = $("#search_term_result").val();
          $(".search_result .search_post .excerpt").highlight(term_result);
          $(".search_result .search_post h3").highlight(term_result);
          $("#docs_result .docTitle").highlight(term_result);
          $("#cert_result .docTitle").highlight(term_result); // Visa fler namnstämplar
          //*********************************************************************

          namnstamp_size = $("#namnstamplar_table tr").size();
          ns = 10;
          $("#namnstamplar_table tr:lt(" + ns + ")").show();

          if (namnstamp_size <= ns) {
            $("#loadMoreNamnstamplar").addClass("disabled");
          }

          $("#loadMoreNamnstamplar").click(function () {
            $(".small_post_page").hide();
            $(".smallDocs").hide();

            if (namnstamp_size == ns) {
              $("#loadMoreNamnstamplar").addClass("disabled");
            } else {
              $("#loadMoreNamnstamplar").addClass("loading");
              setTimeout(function () {
                ns = ns + 8000 <= namnstamp_size ? ns + 8000 : namnstamp_size;
                $("#namnstamplar_table tr:lt(" + ns + ")").show();
                $("#loadMoreNamnstamplar").removeClass("loading");
                $("#loadMoreNamnstamplar").hide();
                $(".smallDocs_total").show();
              }, 1900);
            }
          }); // Visa fler dokument
          //*********************************************************************

          docs_size_li = $("#docs_result tr").size();
          d = 11;
          $("#docs_result tr:lt(" + d + ")").show();
          $("#loadMoreDocs").click(function () {
            $(".smallDocs").hide();

            if (docs_size_li == d) {
              $("#loadMoreDocs").addClass("disabled");
            } else {
              $("#loadMoreDocs").addClass("loading");
              setTimeout(function () {
                d = d + 500 <= docs_size_li ? d + 500 : docs_size_li;
                $("#docs_result tr").show();
                $("#loadMoreDocs").removeClass("loading");
                $("#loadMoreDocs").hide();
                $(".smallDocs_total").show();
              }, 1900);
            }
          }); // Visa fler certifikat
          //*********************************************************************

          docs_size_li = $("#cert_result tr").size();
          d = 11;
          $("#cert_result tr:lt(" + d + ")").show();
          $("#loadMoreCerts").click(function () {
            $(".smallDocs").hide();

            if (docs_size_li == d) {
              $("#loadMoreCerts").addClass("disabled");
            } else {
              $("#loadMoreCerts").addClass("loading");
              setTimeout(function () {
                d = d + 500 <= docs_size_li ? d + 500 : docs_size_li;
                $("#cert_result tr").show();
                $("#loadMoreCerts").removeClass("loading");
                $("#loadMoreCerts").hide();
                $(".smallCerts_total").show();
              }, 1900);
            }
          }); // Visa fler Poster/sidor etc
          //*********************************************************************

          page_post_size = $(".search_result .search_post").size();
          n = 10;
          $(".search_result .search_post:lt(" + n + ")").show();

          if (page_post_size <= n) {
            $("#loadMorePosts").addClass("disabled");
          }

          $("#loadMorePosts").click(function () {
            $(".small_post_page").hide();

            if (page_post_size == n) {
              $("#loadMorePosts").addClass("disabled");
            } else {
              $("#loadMorePosts").addClass("loading");
              setTimeout(function () {
                n = n + 500 <= page_post_size ? n + 500 : page_post_size;
                $(".search_result .search_post:lt(" + n + ")").show();
                $("#loadMorePosts").removeClass("loading");
                $("#loadMorePosts").hide();
                $(".small_post_page_total").show();
              }, 1900);
            }
          }); // Sortera option i selecten för alla ämnesområden i sök föreskrifter & dokument
          //****************************************************

          $(document).ready(function () {
            var options = $("#all_omraden_select option").not(":eq(0)");
            var arr = options.map(function (_, o) {
              return {
                t: $(o).text(),
                v: o.value
              };
            }).get();
            arr.sort(function (o1, o2) {
              return o1.t > o2.t ? 1 : o1.t < o2.t ? -1 : 0;
            });
            options.each(function (i, o) {
              o.value = arr[i].v;
              $(o).text(arr[i].t);
            }); // Makera options

            var omrade = $("#hiddenOmrade").val();
            $("#all_omraden_select option").each(function () {
              var current = $(this).val();

              if (omrade == current) {
                $(this).attr("selected", "selected");
              }
            });
          });
        }); //Slut på sökningen ($(document).ready(function ())
        //*********************************************************************
        // VALIDERING
        //*********************************************************************

        $(document).ready(function () {
          //Validering på formulär
          //***********************
          (function ($, W, D) {
            var JQUERY4U = {};
            JQUERY4U.UTIL = {
              setupFormValidation: function setupFormValidation() {
                //Validation rules // Kursbokning
                $("#booking_course_form").validate({
                  rules: {
                    title: {
                      required: true
                    },
                    swedac_education: {
                      required: true
                    },
                    anstallning_position: {
                      required: true
                    },
                    firstname: {
                      required: true
                    },
                    lastname: {
                      required: true
                    },
                    company: {
                      required: true
                    },
                    country: {
                      required: true
                    },
                    adress: {
                      required: true
                    },
                    phone: {
                      required: true
                    },
                    email: {
                      required: true,
                      email: true
                    }
                  },
                  messages: {
                    swedac_education: "",
                    firstname: "",
                    lastname: "",
                    company: "",
                    adress: "",
                    phone: "",
                    email: "",
                    country: "",
                    title: "",
                    anstallning_position: ""
                  },
                  submitHandler: function submitHandler(form) {
                    checkSubmit(form);
                  }
                }); //Validation rules // Bevakning

                $("#monitor_content").validate({
                  rules: {
                    name: {
                      required: true
                    },
                    email: {
                      required: true,
                      email: true
                    }
                  },
                  messages: {
                    name: "",
                    email: ""
                  },
                  submitHandler: function submitHandler(form) {
                    checkSubmit(form);
                  }
                }); //Validation rules // Bevakning

                $("#monitor_content_form").validate({
                  rules: {
                    name: {
                      required: true
                    },
                    email: {
                      required: true,
                      email: true
                    }
                  },
                  messages: {
                    name: "",
                    email: ""
                  },
                  submitHandler: function submitHandler(form) {
                    checkSubmit(form);
                  }
                }); //Validation rules // Prenumerera på Swedac Magasin

                $("#prenumerera_magasin_form").validate({
                  rules: {
                    namn: {
                      required: true
                    },
                    postadress: {
                      required: true
                    }
                  },
                  messages: {
                    namn: "",
                    postadress: ""
                  },
                  submitHandler: function submitHandler(form) {
                    checkSubmit(form);
                  }
                }); //Validation rules // Klagomål & synpunkter

                $("#klagomal_synpunkter_form").validate({
                  rules: {
                    firstname: {
                      required: true
                    },
                    lastname: {
                      required: true
                    },
                    arende: {
                      required: true
                    },
                    phone: {
                      required: true
                    },
                    message: {
                      required: true
                    },
                    email: {
                      required: true,
                      email: true
                    }
                  },
                  messages: {
                    firstname: "",
                    lastname: "",
                    arende: "",
                    phone: "",
                    message: "",
                    email: ""
                  },
                  submitHandler: function submitHandler(form) {
                    checkSubmit(form);
                  }
                }); //Validation rules // Kontakt

                $("#kontakta_oss_form").validate({
                  rules: {
                    firstname: {
                      required: true
                    },
                    lastname: {
                      required: true
                    },
                    arende: {
                      required: true
                    },
                    message: {
                      required: true
                    },
                    email: {
                      required: true,
                      email: true
                    }
                  },
                  messages: {
                    firstname: "",
                    lastname: "",
                    arende: "",
                    message: "",
                    email: ""
                  },
                  submitHandler: function submitHandler(form) {
                    checkSubmit(form);
                  }
                }); //Validation rules // Bevakning

                $("#bli_teknisk_bedomare_form").validate({
                  rules: {
                    firstname: {
                      required: true
                    },
                    lastname: {
                      required: true
                    },
                    fodelsear: {
                      required: true
                    },
                    phone: {
                      required: true
                    },
                    email: {
                      required: true,
                      email: true
                    },
                    nuvarande_anstallning: {
                      required: true
                    },
                    anstallningar: {
                      required: true
                    },
                    utbildning: {
                      required: true
                    },
                    kompetensomraden: {
                      required: true
                    },
                    omradesspecifika_regelverk: {
                      required: true
                    }
                  },
                  messages: {
                    firstname: "",
                    lastname: "",
                    fodelsear: "",
                    phone: "",
                    email: "",
                    nuvarande_anstallning: "",
                    anstallningar: "",
                    utbildning: "",
                    kompetensomraden: "",
                    omradesspecifika_regelverk: ""
                  },
                  submitHandler: function submitHandler(form) {
                    checkSubmit(form);
                  }
                });
              }
            }; //when the dom has loaded setup form validation rules

            $(D).ready(function ($) {
              JQUERY4U.UTIL.setupFormValidation();
            });
          })(jQuery, window, document); //Sätt klasser på input beroende på händelse vid validering
          //****************************************************


          $(".row input.req, .row select.req, .row textarea.req").blur(function () {
            var $this = $(this);
            var parent = $this.parent(); // console.log($this.val().trim().length);

            if ($(this).val().trim().length != 0) {
              $this.addClass("valid");
              $this.removeClass("error"); // parent.find('.fa-times').css('transform', 'scale(0)');
              // parent.find('.fa-check').css('transform', 'scale(1)');
            } else {
              // parent.find('.fa-check').css('transform', 'scale(0)');
              // parent.find('.fa-times').css('transform', 'scale(1)');
              $this.addClass("error");
              $this.removeClass("valid");
            }
          });
        }); //Slut på Validering ($(document).ready(function ())
        //****************************************************
        // KURSER
        //****************************************************

        $(document).ready(function () {
          // Visa/dölj registeringsformuläret
          //****************************************************
          $(".reg_course").click(function () {
            $("#reg_course").slideToggle();
          }); // Visa dölj fält.
          //****************************************************

          $("#swedac_education").change(function () {
            var str = "";
            $("#swedac_education option:selected").each(function () {
              str += $(this).data("kurs");
              $(".kurstillfalle_section").hide();
              $(".kurs_select").prop("disabled", true);
              $(".kurs_select").val($(".kurs_select option:first").val());
              $(".education").find(".kurstillfalle_section." + str).toggle();
              $(".education").find(".kurs_select." + str).prop("disabled", false);
            });
          }).change(); // Kurser - Skapa fält för extra deltagare
          //****************************************************

          if (window.location.href.indexOf("/?lang=en") > -1) {
            var extra_del = 1;
            $("#create_one_more").click(function () {
              $(".no_deltagare").hide();
              $("#extra_section").append('<div class="field_section"> <span class="remove_item_button" id="more_item_' + extra_del + '"><i class="fa fa-times"></i></span> <div class="row fname"> <input type="text" class="inputbox" name="extra_firstname_' + extra_del + '" id="extra_firstname_' + extra_del + '" placeholder="Firstname" > <i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row lname"><input type="text" class="inputbox" name="extra_lastname_' + extra_del + '" id="extra_lastname_' + extra_del + '" placeholder="Lastname" ><i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row position"><input type="text" class="inputbox" name="extra_position_' + extra_del + '" id="position_' + extra_del + '" placeholder="Position" ><i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row email"><input type="email" class="inputbox" name="extra_email_' + extra_del + '" id="extra_email_' + extra_del + '" placeholder="Email"><i class="fa fa-check"></i><i class="fa fa-times"></i><input type="hidden" name="extra_deltagare_' + extra_del + '"></div><div>');
              $(document).on("click", "#more_item_" + extra_del + "", function () {
                $(this).parent().remove();
              });
              extra_del++;
            });
          } else {
            var extra_del = 1;
            $("#create_one_more").click(function () {
              $(".no_deltagare").hide();
              $("#extra_section").append('<div class="field_section"> <span class="remove_item_button" id="more_item_' + extra_del + '"><i class="fa fa-times"></i></span> <div class="row fname"> <input type="text" class="inputbox" name="extra_firstname_' + extra_del + '" id="extra_firstname_' + extra_del + '" placeholder="Förnamn" > <i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row lname"><input type="text" class="inputbox" name="extra_lastname_' + extra_del + '" id="extra_lastname_' + extra_del + '" placeholder="Efternamn" ><i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row position"><input type="text" class="inputbox" name="extra_position_' + extra_del + '" id="position_' + extra_del + '" placeholder="Position" ><i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row email"><input type="email" class="inputbox" name="extra_email_' + extra_del + '" id="extra_email_' + extra_del + '" placeholder="E-postadress"><i class="fa fa-check"></i><i class="fa fa-times"></i><input type="hidden" name="extra_deltagare_' + extra_del + '"></div><div>');
              $(document).on("click", "#more_item_" + extra_del + "", function () {
                $(this).parent().remove();
              });
              extra_del++;
            });
          }
        }); //Slut på Kurser ($(document).ready(function ())
        //****************************************************
        // HELA MENYN
        //****************************************************

        $(document).ready(function () {
          //Om skärmen är Större eller lika med liggande iphone 6 plus
          if ($(window).width() > 736) {
            // Huvudmeny Visa/dölj
            //****************************************************
            $(".nav > li.menu-item-has-children").hover(function () {
              $(this).children(".sub-menu").addClass("open");
              $(this).toggleClass("open");
              var isOpen = $(this).hasClass("open");
              $(this).next('a').attr('aria-expanded', isOpen);
              $(this).next('button').attr('aria-expanded', isOpen);
            }, function () {
              $(this).children(".sub-menu").removeClass("open");
              $(this).toggleClass("open");
              var isOpen = $(this).hasClass("open");
              $(this).next('a').attr('aria-expanded', isOpen);
              $(this).next('button').attr('aria-expanded', isOpen);
            }); // Topmenyn Visa/dölj
            //****************************************************

            $("#menu-topnavigation-right > li.menu-item-has-children").hover(function () {
              $(this).children(".sub-menu").toggleClass("open");
              $(this).toggleClass("open");
              var isOpen = $(this).hasClass("open");
              $(this).next('a').attr('aria-expanded', isOpen);
              $(this).next('button').attr('aria-expanded', isOpen);
            }); //Engelska

            $("#menu-topnavigation-right-eng > li.menu-item-has-children").hover(function () {
              $(this).children(".sub-menu").toggleClass("open");
              $(this).toggleClass("open");
              var isOpen = $(this).hasClass("open");
              $(this).next('a').attr('aria-expanded', isOpen);
              $(this).next('button').attr('aria-expanded', isOpen);
            });
            $('<span class="grow"></span>').insertAfter(".nav li.menu-item-has-children ul.sub-menu li.menu-item-has-children > a");
            $("li.menu-item-has-children span.grow").click(function () {
              if ($(this).hasClass("active") && $(this).next().addClass("open")) {
                $(this).removeClass("active");
                $(this).next().removeClass("open");
              } else {
                $(this).addClass("active");
                $(this).next().addClass("open");
              }
            }); // Docka Meny
            //****************************************************

            $(window).scroll(function () {
              if ($(this).scrollTop() > 123) {
                $("#header").addClass("fixed");
                $(".toggle-search").slideUp(250);
              } else {
                $("#header").removeClass("fixed");
              }

              if ($(this).scrollTop() > 210) {
                $("#header").addClass("vissible");
              } else {
                $("#header").removeClass("vissible");
              }
            });
          } // Lägg klass på första togglen från admin
          //****************************************************


          $(".symple-toggle").first().addClass("first");
        }); //Slut på MENY ($(document).ready(function())
        //*********************************************************************
        // KALENDER
        //*********************************************************************

        $(document).ready(function () {
          // Visa/Dölj händelse
          //****************************************************
          $(".r_toggle").click(function () {
            var toggle = $(this).attr("data-toggle");
            $(".entry-summary").slideUp(300);
            $(".entry-title").removeClass("active");

            if ($("[data-more=" + toggle + "]").is(":hidden")) {
              $("[data-more=" + toggle + "]").addClass("active");
              $("[data-more=" + toggle + "]").slideToggle(300);
              $("[data-paragraph=" + toggle + "] .entry-title").addClass("active");
            } else {
              $("[data-more=" + toggle + "]").slideUp(300);
              $("[data-more=" + toggle + "]").removeClass("active");
              $("[data-paragraph=" + toggle + "] .entry-title").removeClass("active");
            }
          }); // Kalender - Sätt klass på sida ordet i datumet (Grå ruta)
          //****************************************************

          $(".start_date").each(function () {
            var $this = $(this);
            $this.html($this.html().replace(/(\S+)\s*$/, '<span class="lastWord">$1</span>'));
          }); // Kopiera URL på kalenderhändelse vid klick
          //****************************************************

          var clipboard = new Clipboard(".btn");
          clipboard.on("success", function (e) {
            e.clearSelection();
          });
          clipboard.on("error", function (e) {});
        }); // Slut på Kalender ($(document).ready(function())

        $(window).bind("load", function () {
          if (window.location.href.indexOf("?lang=en") > -1) {
            $(".atcb-link:contains('Lägg till i min kalender')").html("Add to my calendar"); //Ändra namn på länkar
          }
        });
        $(document).ready(function () {
          //Flytta kontaktuppfiterna
          //****************************************************
          $(".kontaktuppgifter_kontor").insertBefore("#anchor_telefon-fax-och-e-post");
        }); // Slut på Kontakt ($(document).ready(function())
      },
      finalize: function finalize() {
        $(".page-content .kontaktuppgifter_kontor iframe").wrap('<div class="video-container" />');
      }
    }
  }; // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire("common"); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      }); // Fire common finalize JS

      UTIL.fire("common", "finalize");
    }
  }; // Load Events

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.


(function ($) {
  $('#top_content li.menu-item-has-children').each(function (i, el) {
    var activatingA = $(this).children('a');
    var btn = '<button><span><span class="visuallyhidden">show submenu for “' + jQuery($(this)).find('a')[0].text + '”</span></span></button>';
    activatingA.after(btn);
    $(this).find('button').on('click', function (event) {
      var parent = $(this).parent();
      parent.toggleClass("open");
      $(this).next('.sub-menu').toggleClass('open');
      var isOpen = parent.hasClass("open");
      parent.next('a').attr('aria-expanded', isOpen);
      $(this).attr('aria-expanded', isOpen);
      event.preventDefault();
    });
  });

  if ($("#spec_links").length) {
    // var topOffset = $('#spec_links').offset().top - $('#page-header-parent').offset().top;
    // var specsHeight = $('#spec_links').innerHeight();
    // var infoboxHeight = $('#infobox').innerHeight();
    // var infoboxTopOffset = topOffset - specsHeight;
    $("#infobox-btn").click(function () {
      $("#infobox").toggleClass("show");
    });
    $(".infobox-close").click(function () {
      $("#infobox").removeClass("show");
    });
  }

  (function ($) {
    jQuery('#header').height(jQuery('#header').height() + jQuery('#top_content').height());
    jQuery('#header>.wrapper').css('margin-top', jQuery('#top_content').height());
  });
})(jQuery); //to listen for changes in the input field


var email = document.querySelector(".femail"); // Listen to any change to the input we selected above

if (_typeof(email) === !"undefined" || email === !null) {
  email.addEventListener("input", inputEmail);
}

function inputEmail(e) {
  // Check if the input is NOT blank first, and if it's not, make sure it matches our regex test
  var input = e.target.value;

  if (input && /(^\w.*@\w+\.\w)/.test(input)) {
    email.classList.add("success");
    email.classList.remove("failure");
  } else {
    email.classList.remove("success");
    email.classList.add("failure");
  }
} // function for hide image and show video swedacskolan


function showVideo(id) {
  var imageDiv = document.querySelector(".imageDiv" + id);
  var videoDiv = document.querySelector(".videoDiv" + id);
  imageDiv.style.display = "none";
  videoDiv.classList.remove("hide");
} // check file type and deactivate submit if wrong fileformat


function fileType(fileInput) {
  var files = fileInput.files;
  var allowedExtensions = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

  for (var i = 0; i < files.length; i++) {
    // loop trough files (.files needs to be in a for loop).
    var type = files[i].type;

    if (allowedExtensions.includes(type)) {
      console.log('Type: ' + type);
    } else {
      // error message and empty input field if wrong extension.
      alert('Filformatet stöds ej!');
      fileInput.value = "";
    }
  }
}

function addMoreAttachment() {
  jQuery('.attachment-row:last').clone().insertAfter('.attachment-row:last');
  jQuery('.attachment-row:last').find('input').val('');
} // rek.ai scripts


jQuery(document).ready(function () {
  // searchbar in headere
  var rekAutocomplete = rekai_autocomplete('#search-input'); // searchbar on search and 404 page

  var rekSearchPageAutocomplete = rekai_autocomplete('#search-page-input');
});
jQuery(document).ready(function ($) {
  $('.submit-button').on('click', function () {
    var formId = $(this).data('form');
    var selectedOption = $('#' + formId).find('select').val();

    if (selectedOption) {
      window.location.href = selectedOption;
      handleSelection();
    }
  }); // Function to handle selection change

  function handleSelection() {
    $("#all_amnesomraden_select").addClass("fadeOut");
    $(".loading-icon").fadeIn(500);
  }

  document.getElementById('monitor_content').addEventListener('submit', function (e) {
    var nameInput = document.getElementsByName('name')[0];
    var emailInput = document.getElementsByName('email')[0];
    var nameErrorText = document.querySelector('.row.name .error-text');
    var emailErrorText = document.querySelector('.row.email .error-text');
    var isValid = true;

    if (nameInput.value.trim() === '') {
      nameInput.classList.add('error');
      nameErrorText.textContent = 'Fyll i namn';
      nameErrorText.style.display = 'block';
      isValid = false;
    } else {
      nameInput.classList.remove('error');
      nameErrorText.style.display = 'none';
    } // Check if the email input is empty


    if (emailInput.value.trim() === '') {
      emailInput.classList.add('error');
      emailErrorText.textContent = 'Fyll i en Epostadress';
      emailErrorText.style.display = 'block';
      isValid = false;
    } else {
      emailInput.classList.remove('error');
      emailErrorText.style.display = 'none';
    } // Check if the email input does not contain "@"


    if (!emailInput.value.includes('@')) {
      emailInput.classList.add('error');
      emailErrorText.textContent = 'E-postadressen måste innehålla "@"';
      emailErrorText.style.display = 'block';
      isValid = false;
    } else {
      emailInput.classList.remove('error');
    } // Check if the email input does not contain "."


    if (!emailInput.value.includes('.')) {
      emailInput.classList.add('error');
      emailErrorText.textContent = 'Fyll i giltlig Epostadress. Tex ".com" eller ".se"';
      emailErrorText.style.display = 'block';
      isValid = false;
    } else {
      emailInput.classList.remove('error');
    }

    if (!isValid) {
      e.preventDefault();
    }
  });
});